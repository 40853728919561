<script>
    import { user, active_budget } from "./stores";
    import { doJSON, downloadBlob, formatMoney } from "./lib";
    import { fetchWithCSRF } from "./lib";
    import { tick } from 'svelte';
    import AutoComplete from "simple-svelte-autocomplete"
    import RequestView from "./RequestView.svelte";

    let items = [];
    let budget = {};
    let start_date = '';
    let end_date = '';
    let name = 'check';
    let ui_name = 'Check';
    let chosen_dealer;
    let districts = [];
    let district_id = '';
    let selected_checks = [];
    let is_printing = false;
    let to_print = [];
    let to_show = [];
    let action = ''

    async function init() {
        let [success, response] = await doJSON('/api/district', false, 'GET');
        districts = response.data;
    }
    init();

    function buildParams() {
        let params = {
            'start_date': start_date,
            'end_date': end_date,
            'account_id': $user.account_id
        }

        if(chosen_dealer) {
            params['dealer_id'] = chosen_dealer.id;
        }

        if(district_id) {
            params['district_id'] = district_id;
        }



        return new URLSearchParams(params).toString();
    }

    function getItems() {
        items = [];
        selected_checks = [];

        let query_string = buildParams();
        doJSON(`/api/${name}?` + query_string, false, 'GET').then(([success, response]) => {
            if(success) {
                items = response.data.checks;
                budget = response.data.budget;
            }
        });
    }

    async function performAction() {
        // If the function returns true, we need to refresh the data
        switch(action) {
            case 'printChecks':
                await printChecks();
                break;
            case 'createPositivePay':
                await createPositivePay();
                break;
            case 'voidChecks':
                await voidChecks();
                break;
            case 'exportRegister':
                await exportRegister();
                break;
            case 'printUnprintedChecks':
                await printUnprintedChecks();
                break;
            case 'printVisibleChecks':
                await printVisibleChecks();
                break;
            case 'printRegister':
                await printRegister();
                break;
            case 'printRequestForms':
                await printRequestForms();
                break;
        }
        action = '';
    }

    async function getCheck(item) {
        let response = await fetchWithCSRF(`/api/${name}/${item.id}`, false, 'GET');
        let newWindow = window.open('/')
        newWindow.location = URL.createObjectURL(await response.blob());
    }

    async function searchDealers(keyword) {
        let [success, response] = await doJSON(`/api/dealer?account_id=${$user.account_id}&q=${keyword}`, null, 'GET');
        if(success) {
            return response.data;
        }
    }

    async function updateYear() {
        start_date = $active_budget.year + '-01-01';
        end_date = $active_budget.year + '-12-31';
    }

    async function printChecks() {
        let response = await fetchWithCSRF('/api/check/print', {'checks': selected_checks}, 'POST');
        let newWindow = window.open('/')
        newWindow.location = URL.createObjectURL(await response.blob());
    }

    async function createPositivePay() {
        let response = await fetchWithCSRF('/api/check/positive-pay', {'checks': selected_checks}, 'POST');
        downloadBlob(await response.blob(), 'text/plain', 'positive-pay.txt');
    }

    async function voidChecks() {
        let query_string = buildParams();
        doJSON(`/api/check/void?` + query_string, {'checks': selected_checks}, 'POST').then(([success, response]) => {
            if(success) {
                items = response.data.checks
                budget = response.data.budget;
            }
        });
    }

    async function exportRegister() {
        let response = await fetchWithCSRF('/api/check/export-register', {'account_id': $user.account_id}, 'POST');
        downloadBlob(await response.blob(), 'text/csv', 'export.csv');
    }

    async function printVisibleChecks() {
        let response = await fetchWithCSRF('/api/check/print', {'q': buildParams()}, 'POST');
        let newWindow = window.open('/')
        newWindow.location = URL.createObjectURL(await response.blob());
    }

    async function printUnprintedChecks() {
        let params = {
            'start_date': start_date,
            'end_date': end_date,
            'printed': false
        };
        params = new URLSearchParams(params).toString();
        let response = await fetchWithCSRF('/api/check/print', {'q': params}, 'POST');
        let newWindow = window.open('/')
        newWindow.location = URL.createObjectURL(await response.blob());

    }

    async function printRequestForms() {
        if(is_printing) {
            alert('Already printing');
            return;
        }
        is_printing = true;

        to_print = [];
        to_show = [];
        for(let i = 0; i < items.length; i++) {
            for(let j = 0; j < items[i].requests.length; j++) {
                let req = items[i].requests[j];
                if(to_print.indexOf(req) == -1) {
                    to_print.push(req);
                }
            }
        }
        to_show.push(to_print.pop());
        to_show = to_show;
    }

    async function loadNextRequest() {
        if(to_print.length == 0) {
            if(to_show.length > 0) {
                await tick();
                print();
            }
            is_printing = false;
            return;
        }
        setTimeout(() => {
            to_show.push(to_print.pop());
            to_show = to_show;
        }, 350);
    }

    async function cancelPrinting() {
        to_show = [];
        to_print = [];
        is_printing = false;
    }

    async function printRegister() {
        let query_string = buildParams();
        let response = await fetchWithCSRF('/api/check/print-register?' + query_string, 'POST');
        let newWindow = window.open('/')
        newWindow.location = URL.createObjectURL(await response.blob());
    }

    updateYear();
    getItems();

let activeYear = $active_budget.year;
$: start_date, getItems();
$: end_date, getItems();
$: district_id, getItems();
$: if($active_budget.year != activeYear) {
    updateYear();
    getItems();
    activeYear = $active_budget.year;
}

</script>

<div class="check-wrap" class:batch={is_printing}>
<h1>{ ui_name }s</h1>

<div class="budgetSummary">
    <ul id="budget_menu">
        <li class="alignright">Checks Total: <span>{ formatMoney(budget.checks) }</span></li>
        <li class="alignright">Year Budget Total: <span>{ formatMoney(budget.amount) }</span></li>
        <li class="alignright">Expended Funds: <span>{ formatMoney(budget.expended) }</span></li>
        <li class="alignright">Pending Settlement: <span>{ formatMoney(budget.pending) }</span></li>
        <li class="last alignright"><strong>Available Balance:</strong> <span>{ formatMoney(budget.amount-budget.expended-budget.pending) }</span></li>
    </ul>
</div>

<div class="filters">
<div class="flex">
    <div>
    <label for="budgetYearFilter">
        <span class="label">Issuing Year</span>
        <div class="select-wrap">
            <select bind:value={$active_budget} id="budgetYearFilter">
                {#each $user.active_dealer.budgets as budget}
                    <option value="{budget}">{budget.year}</option>
                {/each}
            </select>
        </div>
    </label>
    </div>
    <label>
        <span class="label">Start Date</span>
        <input type="date" bind:value={start_date} />
    </label>

    <label>
        <span class="label">End Date</span>
        <input type="date" bind:value={end_date} />
    </label>

    {#if $user.account_id == 1}
    <label>
        <span class="label">District</span>
        <div class="select-wrap">
        <select bind:value="{district_id}">
            <option value=""></option>
            {#each districts as district}
                <option value="{district.id}">{district.name}</option>
            {/each}
        </select>
        </div>
    </label>
    {/if}

    <label for="filter-by-dealer">
        <span class="label">Dealer</span>
        <AutoComplete
            id="filter-by-dealer"
            searchFunction={searchDealers}
            placeholder="Search dealers..."
            delay="200"
            required
            localFiltering={false}
            labelFieldName="name"
            noInputStyles="true"
            minCharactersToSearch="-1"
            lock={true}
            bind:selectedItem="{chosen_dealer}"
            onChange="{getItems}"
            >
            <slot slot="item" let:item>
                <div class="item">
                    <span class="item-number">{item.number}</span> -
                    <span class="item-name">{item.name}</span>
                </div>
            </slot>
        </AutoComplete>
    </label>
</div>
</div>

{#if items.length > 0}
<select bind:value={action} on:change={performAction}>
    <option value="">Choose Action</option>
    <option value="printRegister">Print Check Register</option>
    <option value="exportRegister">Export Check Register</option>
    <option value="printVisibleChecks">Print Visible Checks</option>
    <option value="printUnprintedChecks">Print Unprinted Checks</option>
    <option value="printRequestForms">Print Request Forms</option>
{#if selected_checks.length > 0}
    <option value="printChecks">Print Checks</option>
    <option value="createPositivePay">Create Positive Pay Files</option>
    <option value="voidChecks">Void Checks</option>
{/if}

</select>
<br />
<br />
{/if}

<table class="table user click-table">
    <tr>
        <th>Select</th>
        {#if $user.account_id == 1}
            <th>District</th>
        {/if}
        <th>Check #</th>
        <th>Date</th>
        <th>Payee</th>
        <th>Status</th>
        <th>Check Amt</th>
    </tr>
    {#if items.length == 0 }
    <tr>
        <td style="text-align: center" colspan="7">No { ui_name}s found</td>
    </tr>
    {/if}
    {#each items as item }
        <tr class="tbody">
            <td><input type="checkbox" value={item.id} bind:group="{selected_checks}"/></td>
            {#if $user.account_id == 1}
                <td on:click="{getCheck(item)}">{item.district}</td>
            {/if}
            <td on:click="{getCheck(item)}">{item.number}</td>
            <td on:click="{getCheck(item)}">{item.date}</td>
            <td on:click="{getCheck(item)}">{item.payee}</td>
            <td>
                {#if item.void}
                    <span>VOID</span>
                {:else if item.posted}
                    <span>POSTED</span>
                {/if}
            </td>
            <td on:click="{getCheck(item)}">{item.amount}</td>
        </tr>
    {/each}
</table>
</div>

{#if is_printing}
<div class="progress">
    Printing <span>{to_show.length} / {to_print.length + to_show.length}</span>
    <span class="btn sm-btn" style="display: inline-block;" on:click={cancelPrinting}>X</span>
</div>
<div class="print-wrap">
    {#each to_show as req_id (req_id)}
        <RequestView params={{"id": req_id, "noreload": true}} is_print={true} on:loaded={loadNextRequest}/>
    {/each}
</div>
{/if}

<style>
    .filters label {
        margin-right: 1ch;

    }

    .print-wrap {
        display: none;
    }

    .progress {
        padding: 4px 1em;
        position: fixed;
        bottom: 0;
        right: 10%;
        background: #012b5d;
        color: #fff;
    }

</style>
