<script>
export let item = {};
</script>

{#if item}
<div class="page-break coversheet">
    <div class="to">
        {item.user.name || ''}<br />
        {item.dealer.name || ''}<br />
        {item.dealer.address.address || ''}<br />
        {item.dealer.address.city || '' }, {item.dealer.address.state || ''} {item.dealer.address.zip || ''}<br />
    </div>
    <div class="end">
        <p>PLEASE SIGN CHECK ON NEXT PAGE</p>
        <p style="font-weight: bold; margin-top: 1in">PLEASE MAKE SURE THIS ADDRESS SHOWS IN THE ENVELOPE WINDOW.</p>
    </div>
</div>
{/if}
<style>
.to {
    padding: 2in 0 7in 0.7in;
    font-weight: 800;
    font-size: 20px;
}

.end {
    text-align: center;
    font-size: 20px;
}

</style>
